import axios from '@/plugins/axiosV2'
import utils from "@/helpers/utilidades";

const http = axios(process.env.VUE_APP_API_CONDUCEF + '/catalogos');

export default {
    listar_catalogos(url_options)
    {
        url_options = {...url_options, relations: ['opciones']}
        return http.get(utils.url_options('/',url_options, false));
    },

    listar_catalogos_reune() {
        return http.get('/reune');
    },

    obtener_catalogo(id) {
        return http.get(`/${id}`);
    },

    crear_catalogo(payload) {
        return http.post('/', payload);
    },

    actualizar_catalogo(id, payload) {
        return http.put(`/${id}`, payload);
    }
}