import axios from '@/plugins/axiosV2';

const http = axios(process.env.VUE_APP_API_CONDUCEF + '/sepomex');

export default {

    lista_estados() {
        return http.get('/');
    },

    codigos_postales(codigo) {
        return http.get(`/${codigo}`);
    }
}